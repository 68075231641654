import {
    HBackdrop,
    HModal,
    HModalBody,
    HModalClose,
    HModalFooter,
    HModalHeader,
    HModalOpener,
    HPortal,
    HPortalTarget
} from '@shoper/phoenix_design_system';

const components = [HBackdrop, HModal, HModalBody, HModalClose, HModalFooter, HModalHeader, HModalOpener, HPortal, HPortalTarget];
